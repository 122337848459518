<template>
  <b-container class="mb-7">
    <b-row>
      <b-col id="dashboard">
        <h1 v-if="sessionUser">Welcome {{ sessionUser.name }}</h1>
        <Dashboard />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import Dashboard from '@/components/Dashboard';

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      sessionUser: null
    };
  },
  created: async function () {
    this.sessionUser = await getAuth().sessionUser();
  }
};
</script>

<style scoped>
.container {
  text-align: left;
}

.container-no-sidebar {
  margin-left: auto;
  width: 100%;
}
.card-summary-group {
  padding: 10px;
  width: 250px;
}
.card-summary {
  margin: 10px;
  border-left: 2px solid #cccccc;
  padding-left: 20px;
}
.card-summary-group-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #5db966;
}
.card-summary-title {
  font-weight: bold;
  font-size: 1rem;
}
</style>
