<template>
  <div :class="`inline-block rounded-full font-body font-medium text-white ${color} text-sm py-1 px-4`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    colorClass: {
      type: String,
      default: 'gray',
      validator: (colorClass) => ['gray', 'red', 'green', 'blue', 'yellow', 'purple'].includes(colorClass)
    }
  },
  computed: {
    color() {
      switch (this.colorClass) {
        case 'red':
          return 'bg-red-600';
        case 'green':
          return 'bg-green-500';
        case 'blue':
          return 'bg-blue-600';
        case 'yellow':
          return 'text-black bg-yellow-600';
        case 'purple':
          return 'bg-purple-600';
        default:
          return 'bg-gray-600';
      }
    }
  }
};
</script>
