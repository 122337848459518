<template>
  <b-container class="mb-7">
    <b-row id="onboarding-page">
      <b-col>
        <h1 class="flex flex-wrap font-bold text-5xl mega-title">
          <span>Welcome {{ fullName }}!</span>
        </h1>
        <hr class="border-t-4 border-green-400 mb-4" />
        <h2 class="flex flex-wrap font-header font-medium text-2xl mega-title">
          <span>You are on your way to your first online raffle! </span>
        </h2>
        <p class="text pb-8">
          Now that you're in the Rafflebox system, follow the steps below to get set up to run your raffle. Below are
          the links for you to provide the necessary information to set up your raffle webpage and banking details for
          your merchant account. If at any stage you require clarification or assistance, email
          <a class="text-green-600" href="mailto:help@rafflebox.ca">help@rafflebox.ca</a>.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <OnboardingStripe />
        <OnboardingCreateRaffle />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import OnboardingStripe from '@/components/OnboardingStripe.vue';
import OnboardingCreateRaffle from '@/components/OnboardingCreateRaffle.vue';

export default {
  components: {
    OnboardingStripe,
    OnboardingCreateRaffle
  },
  computed: {
    fullName() {
      return this.$store.state.user.name;
    }
  }
};
</script>

<style>
#no-sidebar-layout .container {
  margin-left: auto;
}
</style>
