import { CountryProvinceAgeModel, Country } from '@rafflebox-technologies-inc/rafflebox-schema';

const getModel = (country) => {
  const countryType = country === Country.CA ? Country.CA : Country.US;

  return CountryProvinceAgeModel[countryType];
};

const getDropdownLabel = (country) => {
  return country === 'CA' ? 'Province' : 'State';
};

export default {
  getDefaultView: (country) => {
    const model = getModel(country);
    const dropdownLabel = getDropdownLabel(country);

    const dropdownOptions = [{ text: `No ${dropdownLabel}`, value: null }];

    if (model && model.provinceState && model.provinceState.length > 0) {
      model.provinceState.forEach((province) => {
        dropdownOptions.push({ text: province.name, value: province.code });
      });
    }

    return { dropdownLabel, dropdownOptions };
  },
  getCreateRaffleModalV2View: (country) => {
    if (!country) {
      return [];
    }

    const model = getModel(country);
    let dropdownOptions = [];

    if (model && model.provinceState && model.provinceState.length > 0) {
      dropdownOptions = model.provinceState.map((province) => {
        return { text: province.name, value: province.code };
      });
    }

    return dropdownOptions;
  },
  getEditRaffleModalView: (country) => {
    if (!country) {
      return null;
    }

    const model = getModel(country);

    let dropdownOptions = [];

    if (model && model.provinceState && model.provinceState.length > 0) {
      dropdownOptions = model.provinceState.map((province) => {
        return { text: province.name, value: province.code };
      });
    }

    const dropdownLabel = getDropdownLabel(country);

    return { dropdownOptions, dropdownLabel };
  },

  getEditBillingView: (country) => {
    if (!country) {
      return [];
    }

    const model = getModel(country);
    let dropdownOptions = [];

    if (model && model.provinceState && model.provinceState.length > 0) {
      dropdownOptions = model.provinceState.map((province) => {
        return { text: province.name, value: province.code };
      });
    }

    const dropdownLabel = getDropdownLabel(country);

    return { dropdownOptions, dropdownLabel };
  }
};
