import Vue from 'vue';
import VueRouter from 'vue-router';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import Home from '@/pages/Home';
import Onboarding from '@/pages/Onboarding';
import Login from '@/pages/Login';
import Logout from '@/pages/Logout';
import store from '@/store';

function lazyLoad(page) {
  return () => import(`@/pages/${page}.vue`);
}

Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'default' },
    component: Home
  },
  {
    path: '/welcome',
    name: 'Onboarding',
    meta: { layout: 'no-sidebar' },
    component: Onboarding
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'blank' },
    component: Login,
    public: true
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { layout: 'blank' },
    component: Logout,
    public: true
  },
  {
    path: '/status',
    name: 'Status',
    meta: { layout: 'blank' },
    component: lazyLoad('Status'),
    public: true
  },
  {
    path: '/raffles',
    name: 'Raffles',
    meta: { layout: 'default' },
    component: lazyLoad('Raffles')
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: { layout: 'default' },
    component: lazyLoad('Orders')
  },
  {
    path: '/order/:id',
    name: 'Order',
    meta: { layout: 'default' },
    component: lazyLoad('Order')
  },
  {
    path: '/donations',
    name: 'Donations',
    meta: { layout: 'default' },
    component: lazyLoad('Donations')
  },
  {
    path: '/users',
    name: 'Users',
    meta: { layout: 'default' },
    component: lazyLoad('Users')
  },
  {
    path: '/user',
    name: 'User',
    meta: { layout: 'default' },
    component: lazyLoad('User')
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: { layout: 'default' },
    component: lazyLoad('Reports')
  },
  {
    path: '/tickets',
    name: 'Tickets',
    meta: { layout: 'default' },
    component: lazyLoad('Tickets')
  },
  {
    path: '/tickets/print-mail',
    name: 'Print Tickets for Mailing',
    meta: { layout: 'blank' },
    component: lazyLoad('PrintTicketsForMailing'),
    public: true
  },
  {
    path: '/stations',
    name: 'Stations',
    meta: { layout: 'default' },
    component: lazyLoad('Stations')
  },
  {
    path: '/devices',
    name: 'Devices',
    meta: { layout: 'default' },
    component: lazyLoad('Devices')
  },
  {
    path: '/device/:id',
    name: 'Device',
    meta: { layout: 'default' },
    component: lazyLoad('Device')
  },
  {
    path: '/customers',
    name: 'Customers',
    meta: { layout: 'default' },
    component: lazyLoad('Customers')
  },
  {
    path: '/customer',
    name: 'Customer',
    meta: { layout: 'default' },
    component: lazyLoad('Customer')
  },
  {
    path: '/goldrush',
    name: 'GoldrushRaffles',
    meta: { layout: 'default' },
    component: lazyLoad('GoldrushRaffles')
  },
  {
    path: '/goldrush-v2',
    name: 'GoldrushRafflesV2',
    meta: { layout: 'default' },
    component: lazyLoad('GoldrushRafflesV2')
  },
  {
    path: '/orders/v2',
    name: 'Orders',
    meta: { layout: 'default' },
    component: lazyLoad('OrdersV2')
  },
  {
    path: '/goldrush/raffle',
    name: 'GoldrushRaffle',
    meta: { layout: 'default' },
    component: lazyLoad('GoldrushRaffle')
  },
  {
    path: '/goldrush/raffle/:id',
    name: 'GoldrushRaffleV2',
    meta: { layout: 'default' },
    component: lazyLoad('GoldrushRaffle')
  },
  {
    path: '/raffle',
    name: 'View Raffle',
    meta: { layout: 'default' },
    component: lazyLoad('ViewRaffle')
  },
  {
    path: '/account',
    name: 'Account',
    meta: { layout: 'default' },
    component: lazyLoad('Account')
  },
  {
    path: '/message-center',
    name: 'Message Center',
    meta: { layout: 'default' },
    component: lazyLoad('MessageCenter')
  },
  {
    path: '/stripe',
    name: 'Stripe',
    meta: { layout: 'default' },
    component: lazyLoad('Stripe')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { layout: 'default' },
    component: lazyLoad('Profile')
  },
  {
    path: '/system-info',
    name: 'SystemInfo',
    meta: { layout: 'default' },
    component: lazyLoad('SystemInfo')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    meta: { layout: 'blank' },
    component: lazyLoad('ForgotPassword'),
    public: true
  },
  {
    path: '/invite-user',
    name: 'Invite User',
    meta: { layout: 'blank' },
    component: lazyLoad('InviteUser'),
    public: true
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    meta: { layout: 'blank' },
    component: lazyLoad('ResetPassword'),
    public: true
  },
  {
    path: '/verify-email',
    name: 'Verify Email',
    meta: { layout: 'blank' },
    component: lazyLoad('VerifyEmail'),
    public: true
  },
  {
    path: '/sign-up',
    name: 'Rafflebox Sign up',
    meta: { layout: 'blank' },
    component: lazyLoad('OrganizationSignup'),
    public: true
  },
  /**
   * Admin Pages
   */
  {
    path: '/admin',
    name: 'Admin',
    meta: { layout: 'default' },
    component: lazyLoad('Admin')
  },
  {
    path: '/admin/organization',
    name: 'Organization',
    meta: { layout: 'default' },
    component: lazyLoad('admin/Organization')
  },
  {
    path: '/admin/tags',
    name: 'Tags',
    meta: { layout: 'default' },
    component: lazyLoad('admin/Tags')
  },
  { path: '*', component: Login }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export const getRouteName = async (name) => {
  const route = routes.find((route) => route.name === name);

  if (name !== 'Onboarding' && !route.public && store.getters.displayOnboarding) {
    return 'Onboarding';
  }

  if (name === 'Onboarding' && !store.getters.displayOnboarding) {
    return 'Home';
  }

  if (route.public) {
    // public
    return;
  }

  if (!route.public && (await getAuth().sessionUser())) {
    // private access
    return;
  }

  // unsure throw them to login page
  return 'Login';
};

router.beforeEach(async (to, _from, next) => {
  const name = await getRouteName(to.name);

  if (name) {
    next({ name });
  } else {
    next();
  }
});

export default router;
