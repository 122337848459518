<template>
  <div></div>
</template>
<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import store from '@/store';

export default {
  async mounted() {
    await getAuth().signOut();
    store.dispatch('SET_USER', undefined);
    store.commit('SET_ORGANIZATION', {});
    store.commit('SET_ONBOARDING', false);
    this.$router.push('/login');
  }
};
</script>
