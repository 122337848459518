<template>
  <div>
    <div>
      <MetabaseDashboard dashboardId="5210a84b-f102-4fb9-891a-cc41e48dec47" height="700" />
    </div>
  </div>
</template>

<script>
import MetabaseDashboard from '@/components/reports/MetabaseDashboard';

export default {
  components: {
    MetabaseDashboard
  },
  data() {
    return {
      salesSummaryUrl: null,
      showDashboardComponents: false
    };
  },
  async created() {
    this.showDashboardComponents = true;
  }
};
</script>

<style></style>
