const onboardingCheck = (organization, raffleCount) => {
  // Determine if Stripe is set up
  const stripeExists = 'stripeConnectedAccountId' in organization;

  // Determine if 0 Raffles.
  const rafflesExist = raffleCount > 0;

  const onboarding = !stripeExists || !rafflesExist ? true : false;

  return onboarding;
};

export default onboardingCheck;
