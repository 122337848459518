<template>
  <div>
    <b-form-group
      id="input-shortlink-form"
      class="input-shortlink-form"
      :invalid-feedback="veeErrors.first('input-shortlink')"
      :class="veeErrors.first('input-shortlink') ? 'is-invalid' : ''"
    >
      <label for="input-shortlink">Raffle Website Link <span class="text-danger">*</span></label>
      <div class="center-spinner">
        <b-input-group :prepend="raffleUrl">
          <b-form-input
            class="mb-sm-0 input-shortlink"
            name="input-shortlink"
            v-bind:value="value"
            v-on:input="onInput"
            v-validate="'required|min:3|shortlink-regex|shortlink-exists'"
            :state="validateState()"
            aria-describedby="input-shortlink-feedback"
            data-vv-as="Raffle Website Link"
            data-vv-delay="500"
            trim
          />
        </b-input-group>
        <b-spinner v-if="checking" class="ml-2" label="Checking shortlink" variant="secondary"></b-spinner>
      </div>
    </b-form-group>
  </div>
</template>
<script>
import VeeValidate from 'vee-validate';
import EventServiceV2 from '@/lib/event-service-v2.js';
import config from '@/config';

const name = 'input-shortlink';

export default {
  props: ['value'],
  data() {
    return {
      checking: false,
      raffleUrl: config.RAFFLEBOX_URL + '/raffle'
    };
  },
  created() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;

    VeeValidate.Validator.extend('shortlink-regex', {
      async validate(shortlink) {
        const tester = /^\w+((-?)\w)*$/;
        return !!tester.test(shortlink);
      },
      getMessage: () => `The Raffle Website Link has invalid characters`
    });

    VeeValidate.Validator.extend('shortlink-exists', {
      async validate(shortlink) {
        return !(await component.shortlinkExists(shortlink));
      },
      getMessage: () => `The Raffle Website Link is already taken`
    });
  },
  methods: {
    validateState() {
      if (this.veeFields[name] && (this.veeFields[name].dirty || this.veeFields[name].validated)) {
        return !this.veeErrors.has(name);
      }

      return null;
    },
    isValid() {
      this.$validator.validate();
      return this.veeFields[name]['invalid'] === false;
    },
    onInput(value) {
      this.$emit('input', value);
    },
    async shortlinkExists(shortlink) {
      this.checking = true;

      try {
        await EventServiceV2.retrieveEvent(shortlink);
        this.checking = false;
        return true;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.checking = false;
          return false;
        }

        // some kind of error happened :(
        this.checking = false;
        return true;
      }
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.value = val.toLowerCase().trim();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.center-spinner {
  display: flex;
  flex-direction: row;
  align-items: center;

  .input-group {
    max-width: calc(100% - 3rem);
  }
}

.is-invalid .invalid-feedback {
  display: block;
}

.input-shortlink-form .input-group-text {
  height: calc(1.5em + 1.2rem + 2px);
}
</style>
